//RUST IMAGES

import rustlastcheat from '../images/rust/rustlastcheat.webp';
import rustdisarray from '../images/rust/rustdisarrayfivestar.webp';
import rustdivision from '../images/rust/rustdivisionfivestar.webp';
import rustdisconnect from '../images/rust/rustdisconnectfivestar.webp';
import rustfluent from '../images/rust/rustfluentfivestar.webp';
import rustquantumpublic from '../images/rust/rustquantumpublicfivestar.webp';
import rustquantumprivate from '../images/rust/rustquantumprivatefivestar.webp';
import rustrustsense from '../images/rust/rustsensefivestar.webp';
import rustaccounts from '../images/rust/fivestarrustaccounts.webp';
import RustMonolith from '../images/rust/Monolith.webp';

//TARKOV IMAGES
import tarkovcoffeelite from '../images/tarkov/tarkovcoffeelitefivestar.webp';
import tarkovcoffeefull from '../images/tarkov/tarkovcoffeefullfivestar.webp';
import tarkovterralabs from '../images/tarkov/tarkovterrafivestar.webp';
import tarkovquantumprivate from '../images/tarkov/tarkovquantumprivatefivestar.webp';

//RAINBOW SIX SIEGE IMAGES
import rainbowklar from '../images/rainbow/rainbowklarfivestar.webp';
import rainbowarmy from '../images/rainbow/rainbowcheatarmyfivestar.webp';

//DAYZ IMAGES
import dayzintel from '../images/dayz/dayzintellesensefivestar.webp';
import KlarDayz from '../images/dayz/Dayzklarfivestar.webp';

//SPOOFER IMAGES
import spooferexception from '../images/spoofer/spooferexception.webp';
import spooferperm from '../images/spoofer/spooferperm.webp';

import permimg from '../images/spoofer/permspooferimg.webp';

//CSGO IMAGES
import csgofecurity from '../images/csgo/csgofecurityfivestar.webp';

//APEX IMAGES
import apexfecurity from '../images/apex/apexfecuirtyfivestar.webp';
import apexcola from '../images/apex/fivestarapexcola.webp';


//BATTLEBIT IMAGES
import battlefecurity from '../images/battlebit/battlebitfecuirtyfivestar.webp';

//MW IMAGES
import mwfecurity from '../images/mw/mwfecuirtyfivestar.webp';
import mwfecurityunlocker from '../images/mw/mwfecuirtyunlockerfivestar.webp';

//VALORANT IMAGES
import valcoffee from '../images/valorant/valorantcoffeefivestar.webp';
import valfecurity from '../images/valorant/valorantcfecuirtyfivestars.webp'; 
import valxorpro from '../images/valorant/fivestarvalorantxorpro.webp';
import valxoralaska from '../images/valorant/fivestarvalorantxoralaska.webp'; 





export const data = [

    //RUST
    {
        name: "Monolith External",
        game: "Rust",
        image: RustMonolith,
        seopath: 'monolith-external',
        status: "Undetected",
        stripe: "https://fivestar.sellhub.cx/product/Monolith-External",
         media: [
            {"img": RustMonolith},
        ],
        week: "44",
        month: "85",
        life: "780",
        features: [
            {
                "title": "INFO",
                "items": [
                    "Undetected",
                    "Customizable",
                    "Low CPU Usage",
                    "External",
                    "Cheap & Simple Usage",
                    "Unique Bypass"
                  ]
            },
            {
                "title": "AIMBOT",
                "items": [
                    "Silent Aimbot",
                    "Memory Aimbot",
                    "Smoothing",
                    "Recoil Comp",
                    "Prediction",
                    "Hit Chance",
                    "Auto Shoot Enemy",
                    "Autofire",
                    "Silent No Spread",
                    "Fastest Possible bullets"
                  ]
            },
            {
                "title": "AIM VISUALS",
                "items": [
                    "Simulate Projectiles",
                    "Aim Marker",
                    "Crosshair",
                    "Fov Circle",
                    "Target Snapline"
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Enemy Tracker",
                    "Players",
                    "Scientists",
                    "Wounded",
                    "Sleeper",
                    "Friends",
                    "Radar",
                    "Hands chams",
                    "FOV Arrows",
                    "Looking at me Alert",
                    "Chams",
                    "Skeleton",
                    "Username",
                    "Box ESP",
                    "Held Item",
                    "Health Bar",
                    "Hotbar",
                    "Clothing",
                    "View Direction",
                    "Raid ESP",
                    "All Resources",
                    "All Lootables",
                    "All World Vehicles",
                    "All Deployables (Including Stash/Trap ESP)",
                    "Crosshair Ammo Indicator",
                    "projectile Trails",
                    "Player Healthbars",
                    "Adjustable Filled box amount",
                    "Draw Hit Notification",
                    "Draw Projectiles"
                  ]
            },
            {
                "title": "UTILITY",
                "items": [
                    "Debug Camera",
                    "3rd Person",
                    "Flyhack",
                    "Interactive Noclip",
                    "Teleport",
                    "Silent Walk",
                    "Omnisprint",
                    "Anti-Flyhack Violations",
                    "Anti-Speed Violations",
                    "Spinbot",
                    "Spiderman",
                    "Smaller Hitbox",
                    "Infinte Jump",
                    "Walk on Water",
                    "Turn Placeables",
                    "Increase Height",
                    "No Fall",
                    "No Slowdown",
                    "No Blackout",
                    "No Collision",
                    "Sign Painter",
                    "Instant Loot",
                    "Instant Revive",
                    "Instant Med",
                    "Instant Med Friend"
                  ]
            },
            {
                "title": "COMBAT",
                "items": [
                    "Recoil",
                    "Full Auto",
                    "Fast Fire",
                    "No Sway",
                    "No Spread",
                    "Increase Velocity",
                    "Can Shoot",
                    "Spoof Hit distance",
                    "Thick Bullet",
                    "Instant Eoka",
                    "Aim in Mountable",
                    "No Melee Slowdown",
                    "No Melee Punch",
                    "2x Melee Distance",
                    "No Aimcone",
                    "Fast Bow",
                    "Instant Charge",
                  ]
            },
            {
                "title": "GAME VISUALS",
                "items": [
                    "Day Hack",
                    "Sky Changer",
                    "Ambient",
                    "Bright Stars",
                    "FOV Changer",
                    "Zoom",
                    "Draw Colliders",
                    "Hardcore Map",
                    "Hardcore Compass",
                    "Disable Grass/Weather Effects"
                  ]
            },
            {
                "title": "CONFIG",
                "items": [
                    "Save Config",
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA ONLY"},
            {"winver": "WINDOWS 10/11 (Some windows 11 users have issues might have to downgrade)"},
        ],
    },
    {
        name: "Monolith script",
        game: "Rust",
        image: RustMonolith,
        seopath: 'Monolith-script',

        status: "Undetected",
        stripe: "https://fivestar.sellhub.cx/product/Monolith-Script",
         media: [
            {"img": RustMonolith},
        ],
        week: "9",
        month: "25",
        life: "94",
        features: [
            {
                "title": "FEATURES",
                "items": [
                    "Perfect 1:1 recreation of the games recoil mechanics",
                    "Customize the control amount x & y axis",
                    "Advanced recoil randomization control",
                    "Recoil smoothing amount configuration",
                    "Supports any sensitivity",
                    "Supports any field of view",
                    "Supports aim sensitivity configurations",
                    "All weapons supported",
                    "All Attachments supported",
                    "Old recoil and new recoil",
                  ]
            },
            {
                "title": "Features",
                "items": [
                    "Ability to hide process on taskbar",
                    "Supports Geforce now",
                    "Extended magazine support",
                    "Burst Module support",
                    "Hip fire compensation",
                    "Rapid fire semi-automatic weapons",
                    "Anti AFK",
                    "Auto scope detection",
                    "Auto weapon detection",
                    "Held item voice over",
                    "Key bind configurations"
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },
    {
        name: "Accounts",
        game: "Rust",
        image: rustaccounts,
        seopath: 'Accounts',

        status: "Undetected",
        stripe: "https://fivestar.sellhub.cx/product/Rust-Accounts",
        media: [
            { img: rustaccounts },

          ],
        fullAccess: "14",
        partialAccess: "2",
        features: [
            {
                "title": "INCLUDES",
                "items": [
                    "17$ Full Access Rust Account"
                  ]
            },
            {
                "title": "NFA",
                "items": [
                    "2$ Temporary Rust Account includes a 3 hour warranty"
                  ]
            },
        ],
        requirments: [
            {"other": "Steam Installed"},
        ],
    },
    
     //SPOOFERS
 {
    name: "Exception",
    game: "Spoofer",
    image: spooferexception,
    seopath: 'Exception-',

    status: "Undetected",
    stripe: "https://fivestar.cheating.store/product/Exception-Spoofer",
    media: [
        {"url": "https://www.youtube.com/embed/wcuccavRugs?si=VxGLFgXZqsIzxvcM"},
    ],
    day: "5",
    week: "13",
    month: "25",
    features: [
        {
            "title": "SPOOFS",
            "items": [
                "Disk",
                "USB",
                "Monitor",
                "GPU",
                "RAM",
                "MOTHERBOARD",
                "NetworkEFI",
            ],
        },
        {
                "title": "OTHER",
                "items": [
                "Anticheats: Easy Anti-Cheat, BattlEye Anti-Cheat, MRAC Anti-Cheat",
                "Supported Interfaces: NVMe (Non-RAID), SATA (AHCI)"
              ]
            
        }

    ],
    requirments: [
        {"card": "NVIDIA & AMD"},
        {"winver": " Windows 10-11 (19041-19045) (Home/Pro)"},
    ],
},

]
